import { graphql } from 'gatsby';

import List from './produits-default';

export default List;

export const query = graphql`
  query Category($id: String!, $offset: Int!, $limit: Int!) {
    category: category(id: { eq: $id }, hidden: { eq: false }) {
      id
      children {
        id
        ... on Category {
          libelle
          traductions {
            en {
              libelle
            }
          }
        }
      }
      libelle
      parent {
        id
        ... on Category {
          id
          libelle
          traductions {
            en {
              libelle
            }
          }
        }
      }
      traductions {
        en {
          libelle
        }
      }
    }
    items: allProduit(
      filter: {
        categoryIds: { eq: $id }
        hidden: { eq: false }
        isOnSale: { eq: true }
      }
      sort: { fields: datetime, order: DESC }
      limit: $limit
      skip: $offset
    ) {
      totalCount
      edges {
        node {
          hidden
          id
          isOnSale
          name
          medias {
            origin
            pathname
            height
            width
          }
          pathname
          price
          traductions {
            en {
              name
            }
          }
        }
      }
    }
  }
`;
